.language-dropdown-wrapper {
  .ant-dropdown-open {
    border-color: var(--blue-200) !important;
    border-width: 1px !important;
    background-color: #ffffff;
  }
}

.sm-language-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      &:hover {
        background-color: var(--blue-50) !important;
      }
      .ant-dropdown-menu-item-active {
        background-color: var(--blue-50) !important;
      }
    }
  }
}
